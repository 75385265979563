import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useNavigate } from "react-router-dom";
import '@styles/CarrouselServices.scss';


const CarrouselServices = ({services}) =>{
    const navigate = useNavigate();

    const options = {
        items: 3,
        margin: 10,
        loop: true,
        autoplay: true,
        lazyLoad: true,
        controlsClass: true,
        nav: true,
        navText: ['<', '>'],
        dots:false,
        responsive: {
            0: { items: 3 },
            700: { items: 4 },
            910: { items: 5 },
            1350: { items: 7 },
            1600: { items: 9 },
            1771: { items: 10 },
        }
    };

    const handleClick = async(selectedOption) => {
        navigate('/search', { state:{filterId: selectedOption} });
    };


    return (
        <div className="carrousel-services">
            <OwlCarousel className='owl-theme owl-carousel-category' {...options}>
                {services.map((item, index) => (
                    <div className='item'  key={index} onClick={() =>handleClick(item)}>
                        <div className="item-content">
                            <div className="img-service">
                                <img src={item.image} alt="firulife-img"  key={index}/>
                            </div>
                            <div className='content-carrousel'>{item.label}</div>
                        </div>
                    </div>
                ))}
            </OwlCarousel>
        </div>
    )
}

export default CarrouselServices;